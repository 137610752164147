// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"acf48247d674fdf23dd43a9e602a830d00701cd9"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://948ec825788fcebada29f6150ef5c568@o4508285686972416.ingest.us.sentry.io/4508320058179584",
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  enabled: ["production", "beta", "staging"].includes(
    process.env.NEXT_PUBLIC_ENVIRONMENT || "",
  ),
  tracesSampleRate: ["production", "beta"].includes(
    process.env.NEXT_PUBLIC_ENVIRONMENT || "",
  )
    ? 0.1
    : 1,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
