import { z } from "zod";
import {
  FeedbackCategory,
  envelopeFont,
  envelopeFontColor,
  envelopeType,
} from "~/drizzle/schema";
import {
  blackjack,
  bradleyHand,
  cathiesHand,
  crappyDan,
  dakota,
  jennaSue,
} from "~/styles/fonts";

const swapFn = <T extends Record<string, S>, S extends string>(obj: T) => {
  const res = {} as any; // I'm not worried about impl safety
  Object.entries(obj).forEach(([key, value]) => {
    res[value] = key;
  });
  return res as { [K in keyof T as T[K]]: K };
};

export const FONT_TO_CLASS_MAP = {
  Standard: "font-serif",
  "Bradley Hand": bradleyHand.className,
  Blackjack: blackjack.className,
  "FG Cathies Hand": cathiesHand.className,
  "Crappy Dan": crappyDan.className,
  Dakota: dakota.className,
  "Jenna Sue": jennaSue.className,
} as const;

export const STATE_MAP = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
} as const;

export const STATE_REVERSE_MAP = swapFn(STATE_MAP);

export const registerSchema = z.object({
  email: z
    .string({
      required_error: "Email address is required.",
    })
    .email("Invalid email address."),
  companyName: z
    .string({
      required_error: "Company name is required.",
    })
    .min(1, "You must enter a company name.")
    .max(255, "Company name must be less than 255 characters."),
  firstName: z
    .string({
      description: "First name is required.",
    })
    .min(1, "First name is required.")
    .max(255, "First name must be less than 255 characters."),
  lastName: z
    .string()
    .min(1, "Last name is required.")
    .max(255, "Last name must be less than 255 characters."),
  address: z
    .string()
    .min(1, "Address is required.")
    .max(255, "Address muse be less than 255 characters."),
  city: z
    .string()
    .min(1, "City is required.")
    .max(255, "City must be less than 255 characters."),
  state: z
    .string()
    .min(1, "Stat is required.")
    .max(2)
    .refine(
      (value) => {
        return Object.keys(STATE_MAP).includes(value);
      },
      {
        message: "Invalid state.",
      },
    ),
  zipCode: z
    .string()
    .trim()
    .min(1, "Zip code is required.")
    .max(10, "Zip code must be less than 10 characters.")
    .regex(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid zip code."),
});

export const returnAddressSchema = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    companyName: z.string().optional(),
    address: z.string(),
    address2: z.string().optional(),
    city: z.string(),
    state: z.string().min(2).max(2),
    zipCode: z.string(),
  })
  .refine(
    (data) => {
      // require first and last if company is not provided
      if (!data.companyName) {
        if (!data.firstName || !data.lastName) {
          return false;
        }
      }
      return true;
    },
    {
      path: ["firstName"],
      message: "First name is required when company is blank.",
    },
  )
  .refine(
    (data) => {
      // require first and last if company is not provided
      if (!data.companyName) {
        if (!data.firstName || !data.lastName) {
          return false;
        }
      }
      return true;
    },
    {
      path: ["lastName"],
      message: "Last name is required when company is blank.",
    },
  );

export const recipientSchema = z
  .object({
    firstName: z.string().nullable().optional(),
    lastName: z.string().nullable().optional(),
    company: z.string().nullable().optional(),

    address: z.string().min(1),
    city: z.string().min(1),
    state: z.string().min(1),
    zipCode: z.string().min(1),

    variables: z.array(
      z.object({
        key: z.string().min(1),
        value: z.string().min(1),
      }),
    ),
  })
  .superRefine((data, refinementContext) => {
    if (!data.company) {
      if (!data.firstName && !data.lastName) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: "First name or last name is required when company is blank.",
        });
      }
    }
  });

const sortableField = z.enum([
  "dateAdded",
  "firstNameLowerCase",
  "lastNameLowerCase",
  "businessName",
  "dateUpdated",
  "email",
  "dnd",
  "source",
]);

const sortDirection = z.enum(["asc", "desc"]);

export const orderSchema = z
  .object({
    ignoreInvalidVariableData: z.boolean().default(false),
    designId: z.number().or(z.string().regex(/^\d+$/).transform(Number)),
    mailClass: z.enum(["FirstClass", "Standard"]),
    size: z.string(),
    proofFront: z.string().nullable(),
    proofBack: z.string().nullable(),
    proofPDF: z.string().nullable(),

    envelopeType: z
      .enum(["Regular", "fullWindow", "doubleWindow", "BiFold"])
      .default("doubleWindow")
      .optional(),
    envelopeFont: z
      .enum([
        "Standard",
        "Bradley Hand",
        "Blackjack",
        "FG Cathies Hand",
        "Crappy Dan",
        "Dakota",
        "Jenna Sue",
      ])
      .default("Standard"),
    envelopeFontColor: z.enum(["Black", "Blue", "Green"]).default("Black"),

    // Letter Specific
    color: z.boolean().default(false),
    printOnBothSides: z.boolean().default(false),
    insertAddressingPage: z.boolean().default(false),
    // TODO Live Stamping

    includeReturnAddress: z.boolean().default(false),
    returnAddress: z
      .object({
        firstName: z.string().optional(),
        lastName: z.string().optional(),
        company: z.string().optional(),
        address: z.string().min(1),
        address2: z.string().optional(),
        city: z.string().min(1),
        state: z.string().min(1),
        zipCode: z.string().min(1),
      })
      .optional(),
    variables: z.array(
      z.object({
        key: z.string().min(1),
        value: z.string().min(1),
      }),
    ),
    contactIds: z.array(z.string()),
    allSelected: z.boolean(),

    queryInput: z
      .object({
        query: z.string().optional(),
        page: z.number().default(1),
        limit: z.number().default(10),
        filters: z.array(
          z.object({
            field: z.string().min(1),
            operator: z.string().min(1),
            value: z.union([z.string(), z.array(z.string())]),
          }),
        ),
        sort: z.array(
          z.object({
            field: sortableField,
            direction: sortDirection,
          }),
        ),
      })
      .optional(),
  })
  .refine(
    (data) => {
      // require first and last if company is not provided
      if (!data.returnAddress?.company) {
        if (!data.returnAddress?.firstName || !data.returnAddress?.lastName) {
          return false;
        }
      }
      return true;
    },
    {
      path: ["returnAddress.firstName"],
      message: "First name is required when company is blank.",
    },
  )
  .refine(
    (data) => {
      // require first and last if company is not provided
      if (!data.returnAddress?.company) {
        if (!data.returnAddress?.firstName || !data.returnAddress?.lastName) {
          return false;
        }
      }
      return true;
    },
    {
      path: ["returnAddress.lastName"],
      message: "Last name is required when company is blank.",
    },
  )
  .superRefine((data, refinementContext) => {
    if (data.size === "811") {
      if (!data.envelopeType) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Envelope type is required for 8.5x11 letters.",
          path: ["envelopeType"],
        });
      }
      if (
        (data.envelopeType === "Regular" || data.envelopeType === "BiFold") &&
        !data.envelopeFont
      ) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Envelope font is required for 8.5x11 letters.",
          path: ["envelopeFont"],
        });
      }
      if (
        (data.envelopeType === "Regular" || data.envelopeType === "BiFold") &&
        !data.envelopeFontColor
      ) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Envelope font color is required for 8.5x11 letters.",
          path: ["envelopeFontColor"],
        });
      }
    }
  });

export const oldOrderSchema = z
  .object({
    designId: z.number().or(z.string().regex(/^\d+$/).transform(Number)),
    mailClass: z.enum(["FirstClass", "Standard"]),
    size: z.string(),
    proofFront: z.string().nullable(),
    proofBack: z.string().nullable(),
    proofPDF: z.string().nullable(),

    envelopeType: z
      .enum(["Regular", "fullWindow", "doubleWindow", "BiFold"])
      .default("doubleWindow")
      .optional(),
    envelopeFont: z
      .enum([
        "Standard",
        "Bradley Hand",
        "Blackjack",
        "FG Cathies Hand",
        "Crappy Dan",
        "Dakota",
        "Jenna Sue",
      ])
      .default("Standard"),
    envelopeFontColor: z.enum(["Black", "Blue", "Green"]).default("Black"),

    // Letter Specific
    color: z.boolean().default(false),
    printOnBothSides: z.boolean().default(false),
    insertAddressingPage: z.boolean().default(false),
    // TODO Live Stamping

    includeReturnAddress: z.boolean().default(false),
    returnAddress: z
      .object({
        firstName: z.string().optional(),
        lastName: z.string().optional(),
        company: z.string().optional(),
        address: z.string().min(1),
        address2: z.string().optional(),
        city: z.string().min(1),
        state: z.string().min(1),
        zipCode: z.string().min(1),
      })
      .optional(),
    recipients: z.array(recipientSchema),
    contacts: z.array(z.any()),
    allSelected: z.boolean(),
    queryInput: z.string().optional(),
  })
  .refine(
    (data) => {
      // require first and last if company is not provided
      if (!data.returnAddress?.company) {
        if (!data.returnAddress?.firstName || !data.returnAddress?.lastName) {
          return false;
        }
      }
      return true;
    },
    {
      path: ["returnAddress.firstName"],
      message: "First name is required when company is blank.",
    },
  )
  .refine(
    (data) => {
      // require first and last if company is not provided
      if (!data.returnAddress?.company) {
        if (!data.returnAddress?.firstName || !data.returnAddress?.lastName) {
          return false;
        }
      }
      return true;
    },
    {
      path: ["returnAddress.lastName"],
      message: "Last name is required when company is blank.",
    },
  )
  .superRefine((data, refinementContext) => {
    if (data.size === "811") {
      if (!data.envelopeType) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Envelope type is required for 8.5x11 letters.",
          path: ["envelopeType"],
        });
      }
      if (
        (data.envelopeType === "Regular" || data.envelopeType === "BiFold") &&
        !data.envelopeFont
      ) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Envelope font is required for 8.5x11 letters.",
          path: ["envelopeFont"],
        });
      }
      if (
        (data.envelopeType === "Regular" || data.envelopeType === "BiFold") &&
        !data.envelopeFontColor
      ) {
        refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Envelope font color is required for 8.5x11 letters.",
          path: ["envelopeFontColor"],
        });
      }
    }
  });

export const designConfigSchema = z
  .object({
    designId: z.number(),
    envelopeType: z.enum(envelopeType.enumValues).optional(),
    envelopeFont: z.enum(envelopeFont.enumValues).optional(),
    envelopeFontColor: z.enum(envelopeFontColor.enumValues).optional(),
    printInColor: z.boolean().optional(),
    printOnBothSides: z.boolean().optional(),
    insertAddressingPage: z.boolean().optional(),
  })
  .superRefine((data, refinementContext) => {
    // if (!data.envelopeType) {
    //   refinementContext.addIssue({
    //     code: z.ZodIssueCode.custom,
    //     message: "Envelope type is required for 8.5x11 letters.",
    //     path: ["envelopeType"],
    //   });
    // }
    // if (data.envelopeType === "Regular" && !data.envelopeFont) {
    //   refinementContext.addIssue({
    //     code: z.ZodIssueCode.custom,
    //     message: "Envelope font is required for 8.5x11 letters.",
    //     path: ["envelopeFont"],
    //   });
    // }
    // if (data.envelopeType === "Regular" && !data.envelopeFontColor) {
    //   refinementContext.addIssue({
    //     code: z.ZodIssueCode.custom,
    //     message: "Envelope font color is required for 8.5x11 letters.",
    //     path: ["envelopeFontColor"],
    //   });
    // }
  });

export const contactSchema = z
  .object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    companyName: z.string().optional(),
    address1: z.string().min(1),
    address2: z.string().optional(),
    city: z.string().min(1),
    state: z.string().min(1),
    postalCode: z.string().min(1),
  })
  .refine((data) => {
    if (!data.companyName && !data.firstName && !data.lastName) {
      return false;
    }
    return true;
  });

export const feedbackSchema = z.object({
  category: z.nativeEnum(FeedbackCategory),
  feedback: z.string().min(1),
});

export const apiKeySchema = z.object({
  apiSecret: z.string().min(1, "API Secret is required"),
});

export const contactFieldFormSchema = z
  .object({
    // overrideMailingAddressFields: boolean("overrideMailingAddress"),
    // mailingAddressField: text("mailingAddressField"),
    // mailingCityField: text("mailingCityField"),
    // mailingStateField: text("mailingStateField"),
    // mailingZipCodeField: text("mailingZipCodeField")

    overrideMailingAddressFields: z.boolean(),
    mailingAddressField: z.string(),
    mailingCityField: z.string(),
    mailingStateField: z.string(),
    mailingZipCodeField: z.string(),
  })
  .refine((data) => {
    if (data.overrideMailingAddressFields) {
      return (
        data.mailingAddressField &&
        data.mailingCityField &&
        data.mailingStateField &&
        data.mailingZipCodeField
      );
    }
    return true;
  }, "All fields are required when overriding mailing address fields.");

export const hslToHex = (hsl: string) => {
  let [h, s, l] = hsl.split(",").map((v) => parseInt(v));
  // @ts-ignore
  l /= 100;
  // @ts-ignore
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    // @ts-ignore
    const k = (n + h / 30) % 12;
    // @ts-ignore
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const rgbToHsl = (r: number, g: number, b: number) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;
  if (max != min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = ((g - b) / d + (g < b ? 6 : 0)) * 60;
        break;
      case g:
        h = ((b - r) / d + 2) * 60;
        break;
      case b:
        h = ((r - g) / d + 4) * 60;
        break;
    }
  }
  return [Math.round(h), Math.round(s * 100), Math.round(l * 100)];
};

export const hexToHsl = (hex: string) => {
  hex = hex.replace(/^#/, "");

  let r = 0,
    g = 0,
    b = 0;
  // 3 digits
  if (hex.length == 3) {
    // @ts-ignore
    r = parseInt(hex[0] + hex[0], 16);
    // @ts-ignore
    g = parseInt(hex[1] + hex[1], 16);
    // @ts-ignore
    b = parseInt(hex[2] + hex[2], 16);
  }
  // 6 digits
  else if (hex.length == 6) {
    // @ts-ignore
    r = parseInt(hex[0] + hex[1], 16);
    // @ts-ignore
    g = parseInt(hex[2] + hex[3], 16);
    // @ts-ignore
    b = parseInt(hex[4] + hex[5], 16);
  }
  let hsl = rgbToHsl(r, g, b);
  return `${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%`;
};

export const getBestTextColor = (hex: string) => {
  // Remove the '#' if it exists
  hex = hex.replace(/^#/, "");

  // Parse the R, G, and B values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the relative luminance
  const luminance = (r * 0.299 + g * 0.587 + b * 0.114) / 255;

  // Return hsl black for light colors and hsl white for dark colors
  return luminance > 0.5 ? "24, 9.8%, 10%" : "0, 0%, 100%";
};

export const scaleImage = (
  width: number,
  height: number,
  maxWidth = 250,
  maxHeight = 50,
) => {
  // Calculate the scaling factor for width and height
  const widthRatio = maxWidth / width;
  const heightRatio = maxHeight / height;

  // Use the smaller scaling factor to maintain the aspect ratio
  const scaleFactor = Math.min(widthRatio, heightRatio);

  // Calculate the new width and height
  const newWidth = Math.round(width * scaleFactor);
  const newHeight = Math.round(height * scaleFactor);

  return { width: newWidth, height: newHeight };
};
