import { clsx, type ClassValue } from "clsx";
import moment from "moment";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

import {
  Contact,
  CustomField,
  CustomValue,
  GHLContact,
  User as GHLUser,
  Location,
  SearchContact,
} from "@repo/ghl-sdk";
import * as CryptoJS from "crypto-js";
import { User } from "~/drizzle/schema";

/**
 * Decrypts an AES encrypted key using a provided decryption key.
 * @param {Buffer} encryptedKey - The encrypted key to decrypt.
 * @param {Buffer} decryptionKey - The key to use for decryption.
 * @returns {Buffer} The decrypted key.
 */
export function decryptAesKey(encryptedKey: string, decryptionKey: string) {
  try {
    const data = CryptoJS.AES.decrypt(encryptedKey, decryptionKey).toString(
      CryptoJS.enc.Utf8,
    );
    console.log("Decrypted key", data);
    return JSON.parse(data);
  } catch (error) {
    console.error("Failed to decrypt key", error);
    return null;
  }
}

export const toTitleCase = (str: string) => {
  if (!str) return "";
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const camelToTitle = (str: string) =>
  str.replace(/([A-Z])/g, " $1").replace(/^./, (s) => s.toUpperCase());

export const useDebounce = (fn: Function, delay: number) => {
  // eslint-disable-next-line no-undef
  let timeout: NodeJS.Timeout;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.apply(this, args), delay);
  };
};

type ContactField = {
  key: string;
  label: string;
  formatter?: (value: string) => string;
};

export const userFields: ContactField[] = [
  { key: "user.firstName", label: "First Name" },
  { key: "user.lastName", label: "Last Name" },
  { key: "user.fullName", label: "Full Name" },
  { key: "user.email", label: "Email" },
  { key: "user.phone", label: "Phone", formatter: formatIntlPhoneNumber },
];

export const locationFields: ContactField[] = [
  { key: "company.name", label: "Name" },
  { key: "company.address", label: "Address" },
  { key: "company.city", label: "City" },
  { key: "company.state", label: "State" },
  { key: "company.logoUrl", label: "Logo URL" },
  { key: "company.country", label: "Country" },
  { key: "company.postalCode", label: "Postal Code" },
  { key: "company.website", label: "Website" },
  { key: "company.email", label: "Email" },
  { key: "company.phone", label: "Phone", formatter: formatIntlPhoneNumber },
];

export const contactFields: ContactField[] = [
  { key: "contact.name", label: "Full Name" },
  { key: "contact.firstName", label: "First Name" },
  { key: "contact.lastName", label: "Last Name" },
  { key: "contact.email", label: "Email" },
  { key: "contact.phone", label: "Phone", formatter: formatIntlPhoneNumber },
  { key: "contact.companyName", label: "Company" },
  { key: "contact.address1", label: "Address Line 1" },
  { key: "contact.city", label: "City" },
  { key: "contact.state", label: "State" },
  { key: "contact.country", label: "Country" },
  { key: "contact.postalCode", label: "Postal Code" },
  { key: "contact.timezone", label: "Timezone" },
  {
    key: "contact.dateOfBirth",
    label: "Date of Birth",
    formatter: getUTCDateAsLocalDate,
  },
  { key: "contact.source", label: "Source" },
  { key: "contact.website", label: "Website" },
  { key: "contact.id", label: "ID" },
];

export const getCustomFieldMap = (
  contact: Contact,
  customFields: CustomField[],
) => {
  const customFieldMap = customFields.reduce(
    (acc, field) => {
      acc[field.id] = field;
      return acc;
    },
    {} as Record<string, CustomField>,
  );

  const customFieldValues = contact.customFields.reduce(
    (acc, field) => {
      const key = customFieldMap[field.id]?.name;
      if (!key) return acc;
      acc[key] = field.value;
      return acc;
    },
    {} as Record<string, string>,
  );

  return customFieldValues;
};

export const substituteFields = (
  text: string,
  customFields: CustomField[],
  customValues: CustomValue[],
  contact: GHLContact,
  user: GHLUser,
  location: Location,
) => {
  const customFieldMap = customFields.reduce(
    (acc, field) => {
      acc[field.fieldKey] = {
        ...field,
        value:
          contact.customFields.find((cf) => cf.id === field.id)?.value || "",
        formatter:
          field.dataType === "DATE" ? getUTCDateAsLocalDate : undefined,
      };
      return acc;
    },
    {} as Record<
      string,
      CustomField & {
        value: string;
        formatter?: (value: string) => string;
      }
    >,
  );

  const customValueMap = customValues.reduce(
    (acc, value) => {
      acc[value.fieldKey.replace("{{ ", "").replace(" }}", "")] = value;
      return acc;
    },
    {} as Record<string, CustomValue>,
  );

  const fields: ContactField[] = [
    ...userFields,
    ...contactFields,
    ...locationFields,
    ...Object.keys(customValueMap).map((key) => ({
      key,
      label: key,
      formatter: undefined,
    })),
    ...Object.keys(customFieldMap).map((key) => ({
      key,
      label: key,
      formatter: customFieldMap[key]?.formatter,
    })),
  ];

  let replacedText = fields.reduce((acc, field) => {
    let value;
    if (field.key === "contact.name") {
      value = `${contact.firstName} ${contact.lastName}`;
    } else if (field.key === "user.fullName") {
      value = `${user.firstName} ${user.lastName}`;
    } else if (customFieldMap[field.key]) {
      value = customFieldMap[field.key]?.value || "";
    } else if (customValueMap[field.key]) {
      value = customValueMap[field.key]?.value || "";
    } else if (field.key.startsWith("user.")) {
      value = (user[field.key.split(".")[1] as keyof GHLUser] as string) || "";
    } else if (field.key.startsWith("company.")) {
      value =
        (location[field.key.split(".")[1] as keyof Location] as string) || "";
    } else {
      value =
        (contact[field.key.split(".")[1] as keyof GHLContact] as string) || "";
    }
    if (field.formatter) {
      value = field.formatter(value);
    }
    return acc.replace(`{${field.key}}`, value);
  }, text);

  if (replacedText.includes("order_date")) {
    const splitKey = replacedText.replace("{", "").replace("}", "").split("|");
    const format = splitKey[1];
    const offset = parseInt(splitKey[2] || "0");
    const dateString = moment().add(offset, "days").format(format);
    replacedText = dateString;
  }
  return replacedText;
};

const addressFieldDefaultMap = {
  mailingAddressField: "address1",
  mailingCityField: "city",
  mailingStateField: "state",
  mailingPostalCodeField: "postalCode",
};

const flattenContact = (
  contact: Contact | SearchContact,
  customFields: CustomField[],
) => {
  const customFieldMap = customFields.reduce(
    (acc, field) => {
      acc[field.id] = field;
      return acc;
    },
    {} as Record<string, CustomField>,
  );

  const customFieldValues = contact.customFields.reduce(
    (acc, field) => {
      const key = customFieldMap[field.id]?.fieldKey.split(".")[1] as string;
      if (!key) return acc;
      acc[key] = field.value;
      return acc;
    },
    {} as Record<string, string>,
  );

  const flattenedContact = {
    ...contact,
    ...customFieldValues,
  };

  return flattenedContact as Record<string, any>;
};

export const getContactAddress = (
  user: User,
  contact: Contact | SearchContact,
  customFields: CustomField[],
) => {
  const flattenedContact = flattenContact(contact, customFields);

  const address = {
    address1: flattenedContact[user.mailingAddressField] || "",
    city: flattenedContact[user.mailingCityField] || "",
    state: flattenedContact[user.mailingStateField] || "",
    postalCode: flattenedContact[user.mailingZipCodeField] || "",
  };

  return address;
};

// Format an E.164 phone number to a US standard format (e.g. (123) 456-7890)
export function formatIntlPhoneNumber(phone: string | undefined) {
  if (!phone) return "";
  return phone.replace(/\+1(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

// Gets the UTC date for a given date timestamp
export function getUTCDateAsLocalDate(date: string | undefined) {
  if (!date) return "";
  const utcDate = new Date(date);
  return new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000,
  ).toLocaleDateString();
}

export const mapSearchContactToGHLContact = (
  searchContact: SearchContact,
  user: User,
  customFields: CustomField[],
): GHLContact => {
  const fullContact = {
    ...searchContact,
    firstName: toTitleCase(searchContact.firstNameLowerCase),
    lastName: toTitleCase(searchContact.lastNameLowerCase),
    contactName: `${searchContact.firstNameLowerCase} ${searchContact.lastNameLowerCase}`,
    company: searchContact.companyName,
    address1: searchContact.address,
  };
  const contactWithAddress = {
    ...fullContact,
    address: getContactAddress(user, fullContact, customFields),
  };
  return contactWithAddress;
};
