"use client";

import { useContext } from "react";
import { WhiteLabelConfigContext } from "~/context/white-label-config";
export function Logo({
  width,
  height
}: {
  width?: number;
  height?: number;
}) {
  const configContext = useContext(WhiteLabelConfigContext);
  return <div className="flex flex-shrink-0 items-center overflow-hidden" style={{
    width: width || configContext?.config?.logoWidth || 250,
    height: height || configContext?.config?.logoHeight || 50
  }} data-sentry-component="Logo" data-sentry-source-file="logo.tsx">
      <img className="h-full w-full object-contain" src={configContext?.config?.enableWhiteLabel && configContext?.config?.logo ? configContext.config.logo : "/pcmi.svg"} alt="Logo" />
    </div>;
}