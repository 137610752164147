"use client";

import { Logo } from "@/components/logo";
import { Button } from "@/components/ui/button";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body className={`flex h-full min-h-screen flex-col items-center justify-center overflow-hidden antialiased`}>
        <div className="flex flex-col items-center gap-4">
          <Logo width={750} data-sentry-element="Logo" data-sentry-source-file="global-error.tsx"></Logo>
          <h1 className="text-xl font-bold">
            An unexpected error has occurred.
          </h1>
          <Button onClick={() => {
          window.location.reload();
        }} data-sentry-element="Button" data-sentry-source-file="global-error.tsx">
            Reload Page
          </Button>
          {/* <NextError statusCode={0} /> */}
        </div>
      </body>
    </html>;
}